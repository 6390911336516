import {
    useState,
    useEffect,
    useContext,
    useRef
} from 'react'
import { Form } from 'react-bootstrap'
import Map, {
    Marker,
    FullscreenControl
} from 'react-map-gl'
import agent from '../../api/agent'
import settings from '../../settings.json'
import { Table } from 'react-bootstrap'
import {
    FullScreen,
    useFullScreenHandle
} from 'react-full-screen';
import { AppContext } from '../../context/AppContext';
import { PointContext } from '../../context/PointContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronRight,
    faChevronLeft,
    faMaximize,
    faMinimize
} from '@fortawesome/free-solid-svg-icons'
import CellPopup from './CellPopup'
import { RefContext } from '../../context/RefContext'
import _ from 'lodash'

const {
    lookup
} = require('zipcodes')
const { abbr } = require('us-state-converter')

const DrinkingWaterTrueQIReport = (props: any) => {

    const {
        loading,
        setLoading,
        viewport,
        setViewport,
        notify
    } = props

    const [reportMarkers, setReportMarkers] = useState<any[] | null>(null)
    const [filteredReports, setFilteredReports] = useState<any[] | null>(null)
    const [reportData, setReportData] = useState<any>()
    const [open, setOpen] = useState(false)
    const [next, setNext] = useState(10)
    const [back, setBack] = useState(0)
    const [toggledReportData, setToggledReportData] = useState<any[]>([])
    const [activeReport, setActiveReport] = useState<null | number>(0)
    const [casNumbers, setCasNumbers] = useState<any[]>([])
    const [casInfo, setCasInfo] = useState<any[]>([])
    const [key, setKey] = useState<any>()
    const [count, setCount] = useState(0)
    const [hover, setHover] = useState(false)

    const [filteredByYear, setFilteredByYear] = useState()

    const [years, setYears] = useState()
    const FullScreenComp = FullScreen as any;
    const fullscreenhandle = useFullScreenHandle() as any;
    const [newZip, setNewZip] = useState()
    const [drinkingWaterQuality, setDrinkingWaterQuality] = useState()

    const mapRef = useRef<any>()
    const { setCurrentRef } = useContext<any>(RefContext)
    const {
        currentPoint,
        setCurrentPoint
    } = useContext<any>(PointContext)
    const {
        selectedHUC8,
        updateContext,
        currentLocation
    } = useContext(AppContext);

    const id = currentPoint.zip

    useEffect(() => {
        setCurrentRef?.(mapRef)
    }, [mapRef])

    useEffect(() => {

        let yearsArray: any = []

        reportData && reportData.map((ele: any) => yearsArray.push(ele.Year))

        // @ts-ignore
        const removeDuplicates: any = [...new Set(yearsArray)];

        setYears(removeDuplicates)

    }, [reportData])

    const expandTable = {
        height: '100vh',
        position: 'absolute',
        top: '10%',
        zIndex: 100
    }

    const defaultMargin = {
        marginTop: '-8rem',
        left: currentPoint.state === 'FL' && '0' || 'unset'
    }

    const flyToPoint = (lng: any, lat: any, zoom: any) => {
        return mapRef.current.flyTo({
            center: [
                parseFloat(lng),
                parseFloat(lat),
            ],
            essential: true,
            zoom: zoom,
        });
    }

    const filterZipcodes = (input: string) => {
        const reports = reportMarkers && reportMarkers.filter((report: any) => report.Huc8 === input)
        setFilteredReports(reports)
    }


    const paginateNext = () => {
        let itemPerPage = 10
        let itemSlice = filteredReports && filteredReports.slice(back, next).length
        let terminalNum = filteredReports && filteredReports.length % itemPerPage
        if (itemSlice !== terminalNum) {
            setNext(prev => prev + itemPerPage)
            setBack(prev => prev + itemPerPage)
            setActiveReport(null)
        }
    }

    const paginateBack = () => {
        let itemPerPage = 10
        if (back > 0) {
            setNext(prev => prev - itemPerPage)
            setBack(prev => prev - itemPerPage)
            setActiveReport(null)
        }
    }

    useEffect(() => {
        filteredReports && handleActiveReport(0)
    }, [filteredReports])

    useEffect(() => {
        setTimeout(() => {
            flyToPoint(
                viewport.longitude,
                viewport.latitude,
                10
            )
        }, 500)

    }, [selectedHUC8])

    useEffect(() => {
        setTimeout(() => {
            reportMarkers && filterZipcodes(selectedHUC8)
        }, 500)
    }, [selectedHUC8, reportMarkers])

    useEffect(() => {

        setTimeout(() => {
            flyToPoint(currentPoint.longitude, currentPoint.latitude, 10)
        }, 500)

        if (id) {

            if (currentPoint.detailsTable === "pa_pwsid_detail_data") agent.Reports.DrinkingWaterPA(currentPoint.pwsid).then(({ body }: any) => {
                setReportData(body)
            })

            if (currentPoint.detailsTable
                === "fl_pwsid_detail_data") agent.Reports.DrinkingWaterFL(currentPoint.pwsid).then(({ body }: any) => {
                    setReportData(body)
                })

            else agent
                .Reports
                .ConsumerConfidenceReport(currentPoint.zip)
                .then(({ body }: any) => {
                    setReportData(body)
                })
        }


    }, [currentPoint.zip])


    const filterArray = (input: any) => {

        _.remove(input, function (e: any) {
            return e === 'Pk' || e === 'pk'
        })

        _.remove(input, function (e: any) {
            return e === 'Dlr'
        })

        _.remove(input, function (e: any) {
            return e === 'Purchased Treated Water Average'
        })

        _.remove(input, function (e: any) {
            return e === 'Purchased Treated Water Range'
        })

        return input
    }

    const changeFLColumnName = (input: any) => {

        input[0] = 'PWSID'
        input[1] = 'Fl PWSID'
        input[2] = 'Mailing Name'
        input[3] = 'System Type'
        input[4] = 'Population Served'
        input[5] = 'CAS Number'
        input[6] = 'Subpart H'
        input[7] = 'Design Capacity'
        input[8] = 'Consecutive Indicator'
        input[9] = 'MCL Federal'
        input[10] = 'Contaminate Group'
        input[11] = 'Contaminate Code'
        input[12] = 'Contaminate'
        input[13] = 'Sample Type'
        input[14] = 'Results'
        input[15] = "Results Number"
        input[16] = "Units of Measure"
        input[17] = 'mcl'
        input[18] = "Sample Date"
        input[19] = "Time"
        input[20] = "Username"
        input[21] = "Location Code"
        input[22] = "Entry Point"
        input[23] = "Lab ID"
        input[24] = "Method"
        input[25] = "Analysis Date"
        input[26] = 'MDL'
        input[27] = 'RDL'
        input[28] = 'Sample Number'
        input[29] = 'Chlorine Residual'
        input[30] = 'Max Quarter'
        input[31] = 'Remarks'

        return input
    }

    const changePAColumnName = (input: any) => {

        input[0] = 'PA PWSID'
        input[1] = 'PWSID'
        input[2] = 'System Name'
        input[3] = 'Population Served'
        input[4] = 'System Type'
        input[5] = 'Activity'
        input[6] = 'Deactivation Date'
        input[7] = 'Contaminate ID'
        input[8] = 'Contaminate Description'
        input[9] = 'Analytic Method'
        input[10] = 'EPA Method'
        input[11] = 'LOC EP ID'
        input[12] = 'LOC Name'
        input[13] = 'Sample Type'
        input[14] = 'Sample Date'
        input[15] = 'Sample Time'
        input[16] = 'Date Received'
        input[17] = 'Analysis Date'
        input[18] = 'Result'
        input[19] = 'Lab ID'
        input[20] = 'Lab Sample ID'
        input[21] = 'Units of Measurement'

        return input
    }

    const changeColumnName: any = (input: any) => {

        input[1] = 'PWSID'
        input[2] = 'Sample Location'
        input[3] = 'CCR Name'
        input[5] = "CAS Number"
        input[6] = "Contaminant"
        input[9] = 'MCL Federal'
        input[10] = 'MCL State'
        input[11] = "MCLG"
        input[12] = "Detected Value"
        input[13] = "Detected Value [Range]"
        input[14] = "Violations"

        return input
    }
    

    const renderColumns = () => {  
        // @ts-ignore
        if (reportData) {
            


            let nameChange: any;
            const columns = Object.keys(reportData![0])
            const filteredCol = filterArray(columns)

            if (currentPoint.detailsTable === "pa_pwsid_detail_data"){
                nameChange = changePAColumnName(filteredCol) 
            }

            if (currentPoint.detailsTable === "fl_pwsid_detail_data") {
                nameChange = changeFLColumnName(filteredCol)
            }

            if (currentPoint.detailsTable === "ccr_data") {
                nameChange = changeColumnName(filteredCol)
            }

            const renderTooltip = (heading: any, phrase: any, index: any) => {
                return (
                    <th
                        key={index}
                        className="header-item popup-header"
                        onMouseEnter={() => {
                            setHover(true)
                            setKey(index)
                        }}
                        onMouseLeave={() => setHover(false)}
                    >
                        {heading}
                        {hover && key === index && (
                            <div className="header-tooltip">
                                <p>{phrase}</p>
                            </div>
                        )}
                    </th>
                )
            }
            

            return nameChange && nameChange.map((block: string, index: number) => {

                if (block === 'MCL Federal') {
                    return renderTooltip(block, 'Federal Maximum Contaminant Level', index)
                }

                if (block === 'MCL State') {
                    return renderTooltip(block, "State Maximum Contaminant Level", index)
                }

                if (block === 'MCLG') {
                    return renderTooltip(block, "Maximum Contaminant Level Goal", index)
                }

                return <th key={index} className="header-item">{block}</th>
            })
        }
    }

    const renderRows = (input: any[]) => {
        const columns = Object.keys(input![0])
        const filteredCol = filterArray(columns)

        const convertDate = (input: any) => `${(new Date(input)).getMonth()}/${(new Date(input)).getDay()}/${(new Date(input)).getFullYear()}`

        return input.map((report: any, index: number) => {
            if (parseInt(report.Average) / parseInt(report['Mcl Federal']) >= .3) {
                return (
                    <tr key={index} className="table-row flagged">
                        {filteredCol.map((item: string, index: number) => {

                            return <td key={index} className="table-data">{report[item]}</td>
                        })
                        }
                    </tr>
                )
            }
            return (
                <tr key={index} className="table-row">
                    {filteredCol.map((item: string, index: number) => {

                        if (item === 'sampdate') {
                            return <td key={index} className="table-data">{convertDate(report[item])}</td>
                        }

                        if (item === 'rcvdate') {
                            return <td key={index} className="table-data">{convertDate(report[item])}</td>
                        }

                        if (item === 'analdate') {
                            return <td key={index} className="table-data">{convertDate(report[item])}</td>
                        }

                        return <td key={index} className="table-data">{report[item]}</td>
                    })
                    }
                </tr>
            )
        })
    }

    const handleActiveReport = (input: number) => {
        setActiveReport(input)
        const report = filteredReports && filteredReports[input]
        if (!report) {
            notify('No data at this location')
            setReportData(null)
        } else {
            agent.Reports.ConsumerConfidenceReport(report.zip).then(data => {
                setReportData(data.body)
            })
        }

        setTimeout(() => {
            flyToPoint(report.longitude, report.latitude, 15)
        }, 100)
    }

    const returnUnique = () => {
        let data = reportData && filteredByYear ? filteredByYear : reportData
        if (data) {
            // @ts-ignore
            const unique = [...new Set(data.map((report: any) => report['Chemical Parameter']))]
            // @ts-ignore
            const cas = [...new Set(data.map((report: any) => report['Cas Number']))]
            setCasNumbers(cas)
        }
    }

    useEffect(() => {
        returnUnique()
    }, [reportData])

    const renderReportSelect = () => {
        if (filteredReports) {
            return (
                <div className="zip-menu">
                    <div className="zip-menu-header">
                        <h3>Zip Codes</h3>
                    </div>
                    {filteredReports.slice(back, next).map((report: any, index: number) => {
                        const className = activeReport === index ? 'report-id active' : 'report-id'
                        return <span onClick={() => handleActiveReport(index)} className={className} key={index}>{report.zip}</span>
                    })}
                </div>
            )
        }
    }

    const renderReportMarkers = () => {

        return filteredReports && filteredReports.map((report: any, index: number) => {

            return (
                <Marker
                    key={index}
                    latitude={report.latitude}
                    longitude={report.longitude}
                >
                    <img
                        width="48px"
                        style={{ cursor: "pointer" }}
                        src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${report.score}.svg`}
                        alt="score_icon"
                    />
                </Marker>
            )

        })
    }

    useEffect(() => {
        if (count > 0) {
            setLoading(true)
            agent.Reports.DrinkingWaterTrueQI()
                .then((data) => {
                    const zipified = data.body.map((element: any) =>
                    ({
                        ...lookup(element["Zip Code"]),
                        Huc8: element["Huc8"],
                        zip: element["Zip Code"],
                        score: element.Score
                    }))
                    setLoading(false)
                    setReportMarkers(zipified)
                })
        }
        setCount(prev => prev + 1)
    }, [currentLocation])


    return (
        <FullScreenComp handle={fullscreenhandle}>
            <button
                onClick={fullscreenhandle.enter}
                className="table-button fullscreen-btn"
            >
                <FontAwesomeIcon className="paginate-btn" icon={faMaximize} />
            </button>
            <br />
            <div className="report-container" id='DrinkingWaterTrueQIReport-report'>
                <section className="section-1">
                    <div className="report-map-container">
                        <Map
                            ref={mapRef}
                            preserveDrawingBuffer={true}
                            mapboxAccessToken={settings.maboxKey}
                            initialViewState={viewport}
                            mapStyle={settings.defaultMapStyle}
                            projection={'mercator'}
                            onMove={(e) => {
                                setViewport({
                                    longitude: e.viewState.longitude,
                                    latitude: e.viewState.latitude,
                                    zoom: e.viewState.zoom,
                                })
                            }}
                        >
                            <FullscreenControl />
                            {currentPoint && (
                                <Marker
                                    latitude={currentPoint.latitude}
                                    longitude={currentPoint.longitude}
                                >
                                    <img
                                        width="48px"
                                        style={{ cursor: "pointer" }}
                                        src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${currentPoint.score}.svg`}
                                        alt="score_icon"
                                    />
                                </Marker>
                            )}
                            {reportData && renderReportMarkers()}
                        </Map>
                    </div>
                </section>
                {/* @ts-ignore */}
                {filteredReports && (
                    <section className="section-2">
                        <FontAwesomeIcon className="paginate-btn" onClick={paginateBack} icon={faChevronLeft} />
                        {renderReportSelect()}
                        <FontAwesomeIcon className="paginate-btn" onClick={paginateNext} icon={faChevronRight} />
                    </section>
                )}
                {reportData && (
                    // @ts-ignore
                    <section style={open ? expandTable : null} className="section-3" >
                        {
                            open ?

                                <button onClick={() => setOpen(false)} className="table-button">
                                    <FontAwesomeIcon className="paginate-btn" icon={faMinimize} />
                                </button>

                                :

                                <button onClick={() => setOpen(true)} className="table-button">
                                    <FontAwesomeIcon className="paginate-btn" icon={faMaximize} />
                                </button>

                        }
                        {/* @ts-ignore */}
                        <div style={open ? { height: '100vh' } : null} className="ccr-graph-container">
                            <Table className="report-table" style={defaultMargin}>
                                <thead className="table-head">
                                    <tr className="column-head">
                                        {reportData.length > 0 && renderColumns()}
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {reportData.length > 0 && renderRows(reportData)}
                                </tbody>
                            </Table>
                        </div>
                        <footer style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <a target='_blank' href="https://trueelements.com/">Copyright 2023, True Elements, Inc</a>
                        </footer>
                    </section>
                )}
            </div>
        </FullScreenComp>
    )
}

export default DrinkingWaterTrueQIReport