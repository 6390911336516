import axios from "axios";
import DrinkingWaterQuality from "../components/DrinkingWaterQuality";
import DrinkingWaterTrueQIReport from "../reports/DrinkingWaterTrueQI/DrinkingWaterTrueQIReport";
import json from '../settings.json'

axios.defaults.baseURL = json.apiBaseURl;
axios.defaults.timeout = json.axiosTimeout;


if (sessionStorage.getItem('authKey')) {
    sessionStorage.clear()
}

async function getToken() {

    await axios.get(`${json.PublicTokenGenerator}`).then(({ data }) => {
        const token = JSON.parse(data.body).access_token
        sessionStorage.setItem("authKey", token);
    
        token && axios.interceptors.request.use((config: any) => {
            if (config.headers) {
    
                let x_api_key: string = config.headers[json.apiHeader] = json.apiKey;
                let keycloakToken: any | string = config.headers['keycloakToken'] = token;
                config.headers = { ...config.headers, 'x-api-key': x_api_key, 'X-Amz-Security-Token': keycloakToken }
    
                return config
            }
            return config;
        })
    }).catch(error => {
        console.log(error)
        getToken()
    })
}

getToken()

axios.interceptors.response.use(async (response: any) => {
    try {
        return response;
    } catch (error) {
        console.log(error);
        return await Promise.reject(error);
    }
});

const responseBody = <T>(response: any) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Reports = {
    DrinkingWaterTrueQI: () => (
      requests.get<any>(
        `${json.apiMethods.getDrinkingWaterTrueQI}?ENV=${json.apiEnvironment}`
      )
        .catch((error) => {
          if (error.response.status === 403) {
            // retry the request
            console.log("that didnt work")
            getToken()
            return requests.get<any>(
              `${json.apiMethods.getDrinkingWaterTrueQI}?ENV=${json.apiEnvironment}`
            );
          } else {
            throw error;
          }
        })
    ),
    DrinkingWaterPA: (pwsid: any) =>
        requests.get<any>(
            `${json.apiMethods.CCRPA}?ENV=${json.apiEnvironment}&PWSID=${pwsid}`
        ),
    DrinkingWaterFL: (pwsid: any) =>
        requests.get<any>(
            `${json.apiMethods.CCRFL}?ENV=${json.apiEnvironment}&PWSID=${pwsid}`
        ),
    ConsumerConfidenceReport: (zip: string) =>
        requests.get<any>(
            `${json.apiMethods.getConsumerConfidence}?ENV=${json.apiEnvironment}&ZIPCODE=${zip}`
        ),
};

const Utilities = {
    LatLngToHuc8: (latitude: number, longitude: number) =>
        requests.get(
            `${json.apiMethods.latLongToHuc8}?ENV=${json.apiEnvironment}&LAT=${latitude}&LNG=${longitude}`
        ),
    LatLngToCity: (latitude: number, longitude: number) =>
        requests.get(
            `${json.apiMethods.GeoCodingAPI}/${longitude},${latitude}.json?types=place&access_token=${json.maboxKey}`
        ),
    EPAHealthEffects: (cas: string) =>
        requests.get(
            `${json.apiMethods.EPAHealthEffects}?ENV=${json.apiEnvironment}&CAS_NUMBER=${cas}`
        ),
};




const agent = {
    Reports,
    Utilities,
    getToken
};

export default agent