import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../context/AppContext';
import { RefContext } from '../../context/RefContext';
import { faGreaterThan, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { Form } from 'react-bootstrap'
import axios from 'axios'
import agent from '../../api/agent';
import settings from '../../settings.json'


const Breadcrumb = ({ 
    viewport, 
    setViewport,
    trackerOn,
    setTrackerOn,
}: any) => {
    const { latitude, longitude } = viewport
    const { pathname } = useLocation()
    const { currentRef, currentReport } = useContext<any>(RefContext);
    const {
        selectedHUC8,
        currentLocation,
        updateContext
    } = useContext(AppContext);

    const [locationInterval, setLocationInterval] = useState<any>()
    const [query, setQuery] = useState()

    const moving = currentRef.current?.isMoving()
   
    useEffect(() => {
        if (trackerOn) {
            if (moving) {
                setLocationInterval(
                    window.setInterval(async () => {
                        await agent.Utilities.LatLngToCity(latitude, longitude)
                            .then(({ features, query }: any) => {
                                query && setQuery(query)
                                updateContext?.(
                                    selectedHUC8,
                                    features[0].place_name,
                                )
                            })
                    }, 500)
                )
            } else {
                clearInterval(locationInterval)
            }
        }

    }, [
        trackerOn,
        moving
    ])

    useEffect(() => {
        if (query) {
            agent.Utilities.LatLngToHuc8(query[1], query[0])
                .then(({ body }: any) => {
                    const huc = body[0].Huc8
                    updateContext?.(
                        huc,
                        currentLocation,
                    );
                })
        }
    }, [query])

    const flyToLocation = async (location: string) => {


        try {
            const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?&country=US&limit=3&access_token=${settings.maboxKey}`)

            const [lng, lat] = data.features[0].center

            setViewport({
                zoom: 12,
                latitude: lat,
                longitude: lng
            })

        } catch (e) {
            console.log(e)
        }
        setTrackerOn(false)
    }

    return (
        <div className="breadcrumb-container">
            <span className='breadcrumb-subtitle'>
                <span className='breadcrumb-subtitle--parent-section'>
                    {currentReport && currentReport.parentReport}
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={faGreaterThan} size='1x' /> &nbsp;&nbsp;
                </span>
                {currentReport && currentReport.reportName}
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faGreaterThan} size='1x' /> &nbsp;&nbsp;
                <span onClick={() => flyToLocation(currentLocation)} className="location">{currentLocation}</span>
            </span>
        </div>
    );
};

export default React.memo(Breadcrumb);

// {
//     pathname === '/' && (
//         <div className="breadcrumb-switch-container">
//             <span><Form.Check type="switch" checked={trackerOn} onChange={() => setTrackerOn(!trackerOn)} /></span>
//             <span className="breadcrumb-switch-title">Search when I move map</span>
//             {/* @ts-ignore */}
//             {trackerOn ? <FontAwesomeIcon className="on-light" icon={faCircle} size="2xs" style={{ color: 'green' }} /> : <div className="spacer"></div>}
//         </div>
//     )
// }