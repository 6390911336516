import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from 'react';
import { RefContext } from '../context/RefContext';
import Map, { FullscreenControl, Source, Layer } from 'react-map-gl';
import agent from '../api/agent';
import DrinkingWaterQuality from '../components/DrinkingWaterQuality';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Accordion } from 'react-bootstrap'
import settings from '../settings.json'

const zipcodes = require('zipcodes');


const Home = ({
  viewport,
  setViewport,
  loading,
  setLoading
}: any) => {

  const [drinkingWaterQuality, setDrinkingWaterQuality] = useState()

  const {
    setCurrentRef,
    setCurrentReport,
  } = useContext<any>(RefContext)

  const report = {
    reportName: 'Drinking Water Quality True QI Scores',
    parentReport: 'Home'
  }

  useEffect(() => {
    setCurrentReport(report)
  }, [])

  const getData = () => {
    setLoading(true);
    agent.Reports.DrinkingWaterTrueQI().then(({ body }) => {
      const zipified = body.map((element: any) => ({
        ...zipcodes.lookup(element['Zip Code']),
        huc8: element.Huc8,
        detailsTable: element['Details Table'],
        pwsid: element['Water Company'],
        score: element.Score,
        popServed: element['Population Served']
      }));
      setDrinkingWaterQuality(zipified);
    })
  }

  useEffect(() => {
    drinkingWaterQuality && setLoading(false);
  }, [drinkingWaterQuality])

  const token = sessionStorage.getItem('authKey')

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        console.log("no token")
        await agent.getToken()
      }
      getData()
    }
  
    fetchData()
  }, [token])



  const mapRef = useRef<any>()

  const bounds = mapRef.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;

  useEffect(() => setCurrentRef(mapRef), [mapRef])

  return (
    <>
      <div className="map-wrapper">
        <Map
          ref={mapRef}
          style={{ position: 'absolute', top: '7%', height: '100%' }}
          longitude={viewport.longitude}
          latitude={viewport.latitude}
          zoom={viewport.zoom}
          mapboxAccessToken={settings.maboxKey}
          initialViewState={viewport}
          projection={'mercator'}
          onMove={(e) => {
            setViewport({
              longitude: e.viewState.longitude,
              latitude: e.viewState.latitude,
              zoom: e.viewState.zoom,
            })
          }
          }
          mapStyle={"mapbox://styles/trueelementsmapping/cle7ge23j000501nt0u0wjnk5"}
        >
          {drinkingWaterQuality && (
            <DrinkingWaterQuality
              list={drinkingWaterQuality}
              bounds={bounds}
              viewport={viewport}
              setLoading={setLoading}
              loading={loading}
            />
          )}
        </Map>
        <div className="map-legend">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Drinking Water True QI</Accordion.Header>
              <Accordion.Body>
                <div className='quality-references'>
                  <h5>True QI Range - What do these scores mean?</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <FontAwesomeIcon style={{ color: '#4fc22c' }} icon={faCircle} size='lg' className='green' />
                        </td>
                        <td style={{ width: '75px' }}>90 - 100</td>
                        <td>No notable irregularities detected</td>
                      </tr>
                      <tr>
                        <td>
                          <FontAwesomeIcon style={{ color: '#ffec43' }} icon={faCircle} size='lg' className='yellow' />
                        </td>
                        <td style={{ width: '75px' }}>81 - 90</td>
                        <td>
                          Potential irregularities detected within a typical range
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FontAwesomeIcon style={{ color: '#ff6666' }} icon={faCircle} size='lg' className='red' />
                        </td>
                        <td style={{ width: '75px' }}>70 - 80</td>
                        <td>Potential irregularities detected, more information available</td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 className="legend-source">Source: True Elements AI Engine Generator</h5>
                  <hr />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>


        </div>
        <div className="copyright">
          <a
            href="https://trueelements.com/"
            target="_blank"
          >
            Powered by True Elements, Inc. Copyright 2023
          </a>
        </div>
      </div>
    </>
  )
}

export default Home