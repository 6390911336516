export const LOCALSTORAGE_DEFAULTS_KEY = 'TE_defaults';

export const getDefaultsFromLocalStorage = (): any => {
    const defaultsStringFromLocalStorage = localStorage.getItem(LOCALSTORAGE_DEFAULTS_KEY);
    if (defaultsStringFromLocalStorage) {
        const { location, huc8 }: any = JSON.parse(defaultsStringFromLocalStorage);

        return { location, huc8 };
    }

    return {
        huc8: '02070010',
        location: 'Washington',
    };
};
export const getDefaultHuc8 = (storeObject: any): string => storeObject.huc8;
export const getDefaultLocation = (storeObject: any): any => storeObject.location;