import { createContext } from 'react';


const defaultAppContextValue = {
    selectedHUC8: '14010001',
    currentLocation: 'Upper Colorado Region',
    updateContext: (huc8: string, location: string) => {}
};

export const AppContext = createContext(defaultAppContextValue);
const AppContextProvider = AppContext.Provider;

export default AppContextProvider;