import { useEffect, useState, useContext } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from 'react-router-dom'
import { PointContext } from '../../context/PointContext'
import { AppContext } from '../../context/AppContext'
import agent from '../../api/agent'


const RenderPopupDW = (props: any) => {

    const {
        current,
        setCurrent,
        reportUrl,
        setLoading
    } = props

    const {
        setCurrentPoint,
        currentPoint,
    } = useContext<any>(PointContext)

    const appContext = useContext(AppContext)

    const [open, setOpen] = useState<boolean>(false)
    const [reveal, setReveal] = useState<boolean>(false)
    const [posX, setPosX] = useState<any>()
    const [posY, setPosY] = useState<any>()

    const navigate = useNavigate()

    const updateContext = (lat: any, lng: any, city: any, report: any) => {

        return agent.Utilities.LatLngToHuc8(lat, lng)
            .then((res: any) => {
                const responseBody = res.body;
                const responseHUC8 = responseBody[0].Huc8;
                if (appContext.updateContext) {
                    appContext.updateContext(responseHUC8, city);
                }

                if (report !== reportUrl[0]) {
                    if (res.body && res.body.length > 0 && responseHUC8) {


                        setLoading(false);
                        navigate(reportUrl);
                    }
                } else {
                    setLoading(false)
                    navigate(report)
                }
            })
    }

    const updateHuc8 = (lat: any, lng: any, report: any) => {
        setLoading(true)
        return agent.Utilities.LatLngToCity(lat, lng).then((data: any) => {
            // @ts-ignore
            const city = current.city
            const state = current.state

            updateContext(lat, lng, city, report)
    
        })
    }

    return (
        <Popup
            // style={{ position: 'relative' }}
            longitude={current.longitude ? current.longitude : current.lng}
            latitude={current.latitude ? current.latitude : current.lat}
            anchor="bottom"
            onClose={() => setCurrent(null)}
        >
            <div id="dw-popup">
                <div className="popup-card" onClick={() => setOpen(false)}>
                    <h3><strong>{current.city && `${current.city},`} {current.state}</strong></h3>
                    {current.zip && (<h4><strong>Zip Code:</strong> {current.zip}</h4>)}
                    <h4>Coordinates: {current.longitude ? current.longitude : current.lng}, {current.latitude ? current.latitude : current.lat}</h4>
                    {current.pounds && (<h4><strong>Pounds:</strong> {current.pounds}</h4>)}
                    {current.twpe && (<h4><strong>twpe:</strong> {current.twpe}</h4>)}
                    <a
                        className="popup-context-item-render-popup"
                        style={{ cursor: 'pointer', paddingRight: 7 }}
                        onClick={() => {
                            setCurrentPoint?.(current)
                            updateHuc8(
                                current.latitude || current.lat,
                                current.longitude || current.lng,
                                reportUrl[0]
                            )
                        }}
                    >
                        Go To Regulated Report
                    </a>
                </div>
            </div>
        </Popup>
    )
}

export default RenderPopupDW