import { useContext, useState } from 'react';
import {
    Navbar,
    Row,
    Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import settings from '../../settings.json'
import axios from 'axios'
import agent from '../../api/agent'

const TELogo = require('../../assets/img/logo.png')
const TrueQ = require('../../assets/img/trueQ.png')

const NavigationBar = ({ viewport, setViewport }: any) => {

    const [queryString, setQueryString] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const [info, setInfo] = useState<null | any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const {
        currentLocation,
        selectedHUC8,
        updateContext
    } = useContext(AppContext)

    const handleContext = (selectedPlaceLat: any, selectedPlaceLng: any, selectedLocation: any) => {
        return agent
            .Utilities
            .LatLngToHuc8(selectedPlaceLat, selectedPlaceLng)
            .then((res: any) => {
                const responseBody = res.body;
                console.log(selectedLocation);
                
                const responseHUC8 = responseBody[0].Huc8 ? responseBody[0].Huc8 : ''
                if (updateContext) {
                    updateContext(
                        responseHUC8,
                        selectedLocation
                    );
                }
            })
    }

    const handleAutoComplete = async (input: string) => {
        setQueryString(input)

        const inputString: any = document.getElementById('search-style')

        try {
            const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${queryString}.json?autocomplete=true&country=US&limit=3&access_token=${settings.maboxKey}`)


            if (data) {
                setInfo(data)
                setOpen(true)
            }

        } catch (e) {
            console.log(e)
        }

        if (inputString?.value === '') setOpen(false)

    }

    const renderList = () => {
        if (info) {
            return (
                <ul className="autocomplete-menu">
                    {info.features.map((address: any) => {
                        return <li onClick={(e: any) => {
                            setQueryString(address.place_name)
                            handleSubmit(e, address.place_name)
                            setOpen(false)
                        }} className="autocomplete-item">{address.place_name}</li>
                    })
                    }
                </ul>
            )
        }
    }

    const handleSubmit = async (e: any, input: any = null) => {
        e.preventDefault();
        setQueryString('')
        try {
            setLoading(true)
            const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&country=US&limit=3&access_token=${settings.maboxKey}`)

            const [lng, lat] = data.features[0].center
            const location = data.features[0].place_name

            console.log(data)

            setViewport({
                zoom: 12,
                latitude: lat,
                longitude: lng
            })



            handleContext(lat, lng, location)

        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    return (
        <Navbar className="col-12 nav-bar position-relative">
            <Row>
                <Col className="col-12 d-flex logo-wrapper">
                    <Col className='col-5'>
                        <Navbar.Brand style={{ cursor: 'pointer' }} onClick={() => navigate('/')} ><img width="150px" src={TELogo} /></Navbar.Brand>
                    </Col>
                    <Col>
                        <form id='search-button' className='input-group form' onSubmit={(e) => {
                            handleSubmit(e)
                            setOpen(false)
                        }}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                            <input
                                id="search-style"
                                type={'text'}
                                onChange={e => handleAutoComplete((e.target.value).toString())}
                                placeholder="Search Address, City, or Zip Code"
                                value={queryString}
                            />
                        </form>
                        <>
                            {info && open && renderList()}
                        </>
                    </Col>
                </Col>
            </Row>
            <div className="trueQI-wrapper">
                <div className='description description-navbar'>
                    Drinking Water Quality
                    <img src={TrueQ} alt="true-q" />
                    Scores
                </div>
            </div>
        </Navbar>
    );
}

export default NavigationBar