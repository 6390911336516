import { SpinnerCircular } from 'spinners-react';

const Spinner = (props: any) => {
    const { active, style } = props;

    return (
        <div
            className='loading-spinner'
            style={{ display: active ? 'flex' : 'none', ...style }}
        >
            <SpinnerCircular />
        </div>
    );
};

export default Spinner;